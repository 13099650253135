import React, { useEffect } from "react";
import assetsImages from "../assets/assets";
import { useLocation } from "react-router-dom";
import { VideoHTMLAttributes } from "react";
const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is provided
      window.scrollTo(0, 0);
    }
  }, [location.hash]);
  return (
    <div className="homepage-main">
      {/* Homepage header starts */}
      <section className="header">
        <div className="homepage-header">
          <div className="container-fluid">
            <div className="container-xl py-4">
              <div className="row">
                <div className="col-md-12 col-lg-7 col-xl-6 px-xl-5 ">
                  <div className="header-title ">
                    <h2 className="mb-0 pe-xl-5">
                      Welcome to Pakistani Brands
                    </h2>
                    <p className="pb-3 pb-xl-5 mb-xl-5 text-white">
                      Your ultimate online destination for all things fashion!
                      Say goodbye to the hassle of browsing multiple websites or
                      stores to find your favorite Pakistani fashion brands.
                      With our all-in-one platform, you can shop from a curated
                      collection of the finest Pakistani brands, all in one
                      convenient place
                    </p>
                  </div>
                  <div className="d-flex Store-Links">
                    <div className="AppStore">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://apps.apple.com/us/app/pakistani-brands/id6476684286"
                      >
                        <img
                          src={assetsImages.AppStore}
                          className="img-fluid"
                          alt=""
                        ></img>
                      </a>
                    </div>
                    <div className="AppStore px-3">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.atrule.fashionapp&hl=en&gl=US"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={assetsImages.GooglePlay}
                          className="img-fluid"
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-5 col-xl-6 ">
                  <div className="header-img text-center py-4">
                    <img
                      src={assetsImages.headerimg1}
                      className="img-fluid "
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Homepage header ends */}
      <section className="py-3 figures-section px-lg-5">
        <div className="container px-lg-5">
          <div className="row">
            <div className="col-6 col-sm-6 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center figures">
                <h1 className=" pb-3 ">7K+</h1>
                <p className="color-primary">Users</p>
              </div>
            </div>
            <div className=" col-6 col-sm-6 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center figures">
                <h1 className=" pb-3 ">5K+</h1>
                <p className="color-primary">Download</p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center figures">
                <h1 className=" pb-3 ">2K+</h1>
                <p className="color-primary">Customers</p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-sm-6 col-md-3 col-lg-3">
              <div className="text-center figures">
                <h1 className=" pb-3 ">200+</h1>
                <p className="color-primary">Brands</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pakistani-brands py-lg-5" id="brands">
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-7 text-center">
              <img
                alt=""
                className="img-fluid"
                src={assetsImages.brandsImg}
              ></img>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5 py-lg-6">
              <div className="brands py-5 py-sm-5 py-md-4 py-lg-0">
                <h1 className="pt-xl-4">All Pakistani Brands</h1>
                <div className="brands py-sm-3 py-md-3 py-lg-5 py-xl-5">
                  <div className="brand-box d-flex">
                    <div className="brand-icon">
                      <img alt="" src={assetsImages.brand1}></img>
                    </div>
                    <div className="brand-detail px-4">
                      <h5>Clothing </h5>
                      <p>
                        From traditional ethnic wear to modern western styles,
                        we've got everything to cater to your fashion needs
                      </p>
                    </div>
                  </div>
                  <div className="brand-box d-flex">
                    <div className="brand-icon">
                      <img alt="" src={assetsImages.brand2}></img>
                    </div>
                    <div className="brand-detail px-4">
                      <h5>Accessories </h5>
                      <p>
                        Elevate your look with our exquisite range of
                        accessories, including footwear, eyewear, bags, and
                        jewelry
                      </p>
                    </div>
                  </div>
                  <div className="brand-box d-flex">
                    <div className="brand-icon">
                      <img alt="" src={assetsImages.brand3}></img>
                    </div>
                    <div className="brand-detail px-4">
                      <h5>Fragrances </h5>
                      <p>
                        Find your signature scent from our selection of
                        luxurious fragrances, perfect for any occasion
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex Store-Links pt-lg-4">
                  <div className="AppStore">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/us/app/pakistani-brands/id6476684286"
                    >
                      <img
                        src={assetsImages.AppStore}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                  <div className="AppStore px-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.atrule.fashionapp&hl=en&gl=US"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={assetsImages.GooglePlay}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-section py-5 text-white" id="features">
        <div className="container-xl py-lg-5">
          <div className="title text-center ">
            <h1> App Features</h1>
            <p className="text-white pb-lg-5">
              Experience the convenience of shopping with "Pakistani Brands" app
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="feature-box text-center px-lg-5">
                <img
                  className="img-fluid"
                  alt=""
                  src={assetsImages.feature1}
                ></img>
                <h4 className="py-3">Personalized Wishlist</h4>
                <p className="text-white">
                  Create your personalized wishlist to keep track of your
                  favorite items
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="feature-box text-center px-lg-5">
                <img
                  alt=""
                  className="img-fluid"
                  src={assetsImages.feature2}
                ></img>
                <h4 className="py-3">Friendly Filters</h4>
                <p className="text-white">
                  Effortlessly search and sort through our vast selection with
                  user-friendly filters
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="feature-box text-center px-lg-5">
                <img
                  alt=""
                  className="img-fluid"
                  src={assetsImages.feature3}
                ></img>
                <h4 className="py-3">Product Details</h4>
                <p className="text-white">
                  Get detailed product insights for informed shopping on our
                  intuitive detail pages
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="sales-and-discount py-lg-5 border border-top-0 border-start-0 border-end-0"
        id="sales"
      >
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-6 text-center">
              <img
                alt=""
                className="img-fluid"
                src={assetsImages.salesImage}
              ></img>
            </div>
            <div className="col-sm-12 col-md-7 col-lg-6 py-lg-6">
              <div className="sales-and-discount py-5 py-sm-5 py-md-4 py-lg-0">
                <h1 className="pt-xl-5">Sales & Discounts</h1>
                <p>
                  Get ready to save big on your favorite brands! Our app keeps
                  you informed about ongoing sales and discounts across various
                  brands, ensuring you always get the best deals. Say hello to
                  savvy shopping and goodbye to overspending
                </p>

                <div className="d-flex Store-Links pt-lg-4">
                  <div className="AppStore">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/us/app/pakistani-brands/id6476684286"
                    >
                      <img
                        src={assetsImages.AppStore}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                  <div className="AppStore px-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.atrule.fashionapp&hl=en&gl=US"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={assetsImages.GooglePlay}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-section">
        {/* <video
          src={assetsImages.introvideo}
          width="100%"
          height="auto"
          controls
        /> */}
        <iframe
          width="100%"
          height="515"
          src="https://www.youtube.com/embed/GE-sVhHo8hE?si=Ha35G4iiAXqfID7J"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </section>
      <section className="download-now-section py-lg-5">
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 py-lg-5">
              <div className="download-content py-5 py-sm-5 py-md-4 py-lg-0">
                <h1 className="pt-xl-5">Download App Now!</h1>
                <p>
                  Level up your fashion game with "Pakistani Brands"! Get the
                  latest trends, convenience, and savings all at your fingertips
                  – download now!
                </p>
                <div className="d-flex Store-Links pt-lg-4">
                  <div className="AppStore">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/us/app/pakistani-brands/id6476684286"
                    >
                      <img
                        src={assetsImages.AppStore}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                  <div className="AppStore px-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.atrule.fashionapp&hl=en&gl=US"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={assetsImages.GooglePlay}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <img
                className="img-fluid"
                alt=""
                src={assetsImages.downloadSectionImg}
              ></img>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
