import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Home from "./Pages/Home";
import Blogs from "./Pages/Blogs";
import BlogDetails from "./Pages/BlogDetails";
import Page404 from "./Pages/Page404";
import TagManager from "react-gtm-module";
import { BrowserRouter, Route, Routes } from "react-router-dom";
function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "G-5LKB1T8GPW" });
  }, []);
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Blogs/blog-name" element={<BlogDetails />} />
        <Route path="/*" element={<Page404 />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
