import React, { useState } from "react";
import assetsImages from "../assets/assets";
import { Link } from "react-router-dom";
const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const closeOffcanvas = () => setShowOffcanvas(false);
  return (
    <div>
      <div className="navbar-main">
        <div className="container-fluid">
          <div className="container-xl">
            <div className="row">
              <nav className="navbar navbar-expand-md ms-auto py-3">
                <div className="col-2 col-sm-1 col-md-2 d-block d-sm-block d-md-none">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setShowOffcanvas(true)}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>

                <div className="col col-sm-3 col-md-2 col-lg-2 text-end">
                  <Link className="navbar-brand" to="/">
                    <img alt="" className="img-fluid" src={assetsImages.Logo} />
                  </Link>
                </div>

                <div className="col-md-7 col-lg-8 col-xl-8 d-none d-sm-none d-md-block">
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                  >
                    <ul className="navbar-nav align-items-center">
                      <li className="nav-item main-nav-link px-2">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link" to="/Home#brands">
                          Brands
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/Home#features">
                          Feature
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/Home#sales">
                          Sale
                        </Link>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <a
                          className="nav-link "
                          target="_blank"
                          href="https://atrule.com/about/"
                        >
                          About
                        </a>
                      </li>
                      <li className="nav-item main-nav-link px-2">
                        <Link className="nav-link " to="/blogs">
                          Blogs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-4 col-sm-3 col-md-3 col-lg col-xl text-end">
                  <a
                    target="_blank"
                    href="https://atrule.com/contactus/"
                    className="contact-btn text-decoration-none"
                  >
                    <span className="">Contact Us</span>
                  </a>
                </div>
                <div className="col-12 d-block d-sm-block d-md-none">
                  <div
                    className={`offcanvas offcanvas-start ${
                      showOffcanvas ? "show" : ""
                    }`}
                    id="side-navbar"
                  >
                    <div className="offcanvas-header">
                      <Link
                        className="navbar-brand"
                        to="/"
                        onClick={closeOffcanvas}
                      >
                        <img className="img-fluid" src={assetsImages.Logo} />
                      </Link>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <ul className="navbar-nav ms-auto">
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/"
                            onClick={closeOffcanvas}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link"
                            onClick={closeOffcanvas}
                            to="Home#brands"
                          >
                            Brands
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link"
                            onClick={closeOffcanvas}
                            to="Home#features"
                          >
                            Features
                          </Link>
                        </li>
                        <li className="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link"
                            onClick={closeOffcanvas}
                            to="Home#sales"
                          >
                            Sale
                          </Link>
                        </li>
                        <li className="nav-item main-nav-linkborder border-top-0 border-start-0 border-end-0">
                          <a
                            className="nav-link "
                            target="_blank"
                            onClick={closeOffcanvas}
                            href="https://atrule.com/about/"
                          >
                            About
                          </a>
                        </li>
                        <li className="nav-item main-nav-linkborder border-top-0 border-start-0 border-end-0">
                          <Link
                            className="nav-link "
                            onClick={closeOffcanvas}
                            to="/blogs"
                          >
                            Blogs
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
