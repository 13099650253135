import Logo from "./logo.webp";
import footerLogo from "./footer-logo.png";
import AppStore from "./AppStore.webp";
import GooglePlay from "./GooglePlay.webp";
import appStoreWhite from "./AppStoreWhite.webp";
import googlePlayWhite from "./GooglePlayWhite.webp";
import GooglePlayDark from "./GooglePlayDark.png";
import appStoreDark from "./AppStoreDark.png";
import fbIcon from "./fb.png";
import twitterIcon from "./twitter.png";
import youtubeIcon from "./youtube.png";
import pinterestIcon from "./pinterest.png";
import petrolPiceApp from "./Petrol Price.png";
import motorwayApp from "./Motorway Conditions Today.png";
import posingApp from "./Posing Camera.png";
import calorie from "./Food Calories.png";
import currencyApp from "./Currency Converter.png";
import headerimg1 from "./headerimg1.png";
import downloadSectionImg from "./download-section-img.webp";
import salesImage from "./Sales.webp";
import feature1 from "./feature1.webp";
import feature2 from "./feature2.webp";
import feature3 from "./feature3.webp";
import brandsImg from "./all-brands-section.webp";
import brand1 from "./icon1.webp";
import brand2 from "./icon2.webp";
import brand3 from "./icon3.webp";

import blogImage1 from "./Blogs/blog1.webp";
import blogImage2 from "./Blogs/blog2.webp";
import blogImage3 from "./Blogs/blog3.webp";
import blogImage4 from "./Blogs/blog4.webp";
import blogImage5 from "./Blogs/blog5.webp";
import blogImage6 from "./Blogs/blog6.webp";
import blogDetail from "./Blogs/blog-detail-img.webp";

import introvideo from "./intro-video.mp4";

const assetsImages = {
  introvideo,
  Logo,
  footerLogo,
  AppStore,
  GooglePlay,
  appStoreWhite,
  googlePlayWhite,
  GooglePlayDark,
  appStoreDark,
  fbIcon,
  twitterIcon,
  youtubeIcon,
  pinterestIcon,
  petrolPiceApp,
  motorwayApp,
  posingApp,
  calorie,
  currencyApp,
  headerimg1,
  blogImage1,
  blogImage2,
  blogImage3,
  blogImage4,
  blogImage5,
  blogImage6,
  blogDetail,
  downloadSectionImg,
  salesImage,
  feature1,
  feature2,
  feature3,
  brandsImg,
  brand1,
  brand2,
  brand3,
};
export default assetsImages;
